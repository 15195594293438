var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canView)?_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"px-1 py-1"},[_c('v-card-text',[_c('p',{staticClass:"text-h5 text--primary"},[_vm._v("未使用總點數")]),_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalUnusedPoints,"title":"未使用總點數"}})],1),(!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm))?_c('v-col',{staticStyle:{"font-size":"2.4rem"},attrs:{"cols":"1","align":"center"}},[_vm._v(" ＝ ")]):_vm._e(),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.annualBudgetSum,"title":"期別預算點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.departmentBudgetSum,"title":"單位預算點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.campaignBudgetSum,"title":"活動預算點數"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"px-1 py-1"},[_c('v-card-text',[_c('p',{staticClass:"text-h5 text--primary"},[_vm._v("已使用總點數")]),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"align":"center","fill-height":""}},[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalUserPoints +
                        _vm.stats.totalStorePoints +
                        _vm.stats.totalUsedExpiredPoints +
                        _vm.stats.totalWithdrawingPoints,"title":"已使用總點數"}})],1),(!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm))?_c('v-col',{staticStyle:{"font-size":"2.4rem"},attrs:{"cols":"1","align":"center"}},[_vm._v(" ＝ ")]):_vm._e(),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalUserPoints,"title":"會員持有點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalStorePoints,"title":"店家持有點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalWithdrawingPoints,"title":"請款中點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalUsedExpiredPoints,"title":"已過期總點數"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"px-1 py-1"},[_c('v-card-text',[_c('p',{staticClass:"text-h5 text--primary"},[_vm._v("已清算總點數")]),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"align":"center","fill-height":""}},[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalWithdrawedPoints +
                        _vm.stats.totalWrittenOffPoints,"title":"已清算總點數"}})],1),(!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm))?_c('v-col',{staticStyle:{"font-size":"2.4rem"},attrs:{"cols":"1","align":"center"}},[_vm._v(" ＝ ")]):_vm._e(),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalWithdrawedPoints,"title":"已請款總點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalWrittenOffPoints,"title":"已註銷總點數"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.totalUnusedPoints +
            _vm.stats.totalUserPoints +
            _vm.stats.totalStorePoints +
            _vm.stats.totalUsedExpiredPoints +
            _vm.stats.totalWithdrawingPoints,"title":"總點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.memberCount,"title":"目前會員數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.storeCount,"title":"目前特店數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.transactionPointsToday,"title":"本日交易點數"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.rewardPointsToday,"title":"本日活動贈點"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('CardStatNumber',{attrs:{"number":_vm.stats.allocatedPointsToday,"title":"本日新增預算點數"}})],1)],1),_c('v-row',{attrs:{"align":"stretch"}},[(_vm.isTopNLoaded)?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('pointTopN',{attrs:{"table-data":_vm.topN.topUsers,"type":"member","title":"Top10 會員點數"}})],1):_vm._e(),(_vm.isTopNLoaded)?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('pointTopN',{attrs:{"table-data":_vm.topN.topStores,"type":"store","title":"Top10 特店未請款點數"}})],1):_vm._e(),(_vm.isTopNLoaded)?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('pointTopN',{attrs:{"table-data":_vm.topN.topTransactions,"type":"store","title":"Top10 本日點數交易記錄"}})],1):_vm._e()],1)],1):_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_vm._v(" 您尚未被授權檢視此頁面 ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }