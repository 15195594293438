<template>
  <v-card
    class="border-radius-xl card-shadow"
    elevation="2"
    style="height: 100%"
  >
    <div class="px-4 py-4 text-center">
      <h5 class="text-gradient text-secondary font-weight-bolder text-h5">
        <span>{{ title }}</span>
      </h5>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      :loading-text="$t(`common['Loading... Please wait']`)"
      :no-data-text="$t(`common['No Data']`)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.sn="{ index }">
        <span>{{ index + 1 }} </span>
      </template>
      <template v-slot:item.total="{ item }">
        <div>
          <span>{{ formatNumber(item.total, 0) }} </span>
          <span>點</span>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatNumber } from "src/util/utils";
export default {
  name: "PointTopN",
  components: {},
  props: {
    type: {
      type: String,
      default: "member",
      required: true,
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formatNumber: formatNumber,
      tableMemberHeaders: [
        {
          text: "排名",
          sortable: false,
          value: "sn",
        },
        {
          text: "會員姓名",
          sortable: false,
          value: "name",
        },
        {
          text: "持有點數",
          sortable: false,
          value: "total",
        },
      ],
      tableStoreHeaders: [
        {
          text: "排名",
          sortable: true,
          value: "sn",
        },
        {
          text: "商店名稱",
          sortable: true,
          value: "name",
        },
        {
          text: "持有點數",
          sortable: true,
          value: "total",
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return this.type.toLowerCase() === "member"
        ? this.tableMemberHeaders
        : this.tableStoreHeaders;
    },
  },
};
</script>
