<template>
  <div>
    <v-container v-if="canView" fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="px-1 py-1">
            <!-- <v-col sm="4">
              <v-avatar
                color="bg-gradient-default border-radius-xl mt-n8"
                class="shadow-dark"
                height="64"
                width="64"
              >
                <v-icon class="material-icons-round text-white" size="24"
                  >weekend</v-icon
                >
              </v-avatar>
            </v-col> -->
            <v-card-text>
              <p class="text-h5 text--primary">未使用總點數</p>
              <v-row align="stretch">
                <v-col md="12">
                  <v-row align="center">
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalUnusedPoints"
                        title="未使用總點數"
                      ></CardStatNumber>
                      <!-- <v-card
                        class="border-radius-xl card-shadow"
                        elevation="2"
                      >
                        <div class="px-4 py-4 text-center">
                          <h1
                            class="
                              text-gradient text-primary
                              font-weight-bolder
                              text-h1
                            "
                          >
                            <span>{{
                              formatNumber(stats.totalUnusedPoints, 0)
                            }}</span>
                            <span class="text-lg"></span>
                          </h1>
                          <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                            未使用總點數
                          </h6>
                          <p class="opacity-8 mb-0 text-sm text-typo"></p>
                        </div>
                      </v-card> -->
                    </v-col>
                    <v-col
                      v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      cols="1"
                      align="center"
                      style="font-size: 2.4rem"
                    >
                      ＝
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.annualBudgetSum"
                        title="期別預算點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.departmentBudgetSum"
                        title="單位預算點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.campaignBudgetSum"
                        title="活動預算點數"
                      ></CardStatNumber>
                    </v-col>
                    <!-- <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalUnusedExpiredPoints"
                        title="已過期總點數"
                      ></CardStatNumber>
                    </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card class="px-1 py-1">
            <v-card-text>
              <p class="text-h5 text--primary">已使用總點數</p>
              <v-row>
                <v-col md="12">
                  <v-row align="center" fill-height>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="
                          stats.totalUserPoints +
                          stats.totalStorePoints +
                          stats.totalUsedExpiredPoints +
                          stats.totalWithdrawingPoints
                        "
                        title="已使用總點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col
                      v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      cols="1"
                      align="center"
                      style="font-size: 2.4rem"
                    >
                      ＝
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalUserPoints"
                        title="會員持有點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalStorePoints"
                        title="店家持有點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalWithdrawingPoints"
                        title="請款中點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalUsedExpiredPoints"
                        title="已過期總點數"
                      ></CardStatNumber>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card class="px-1 py-1">
            <v-card-text>
              <p class="text-h5 text--primary">已清算總點數</p>
              <v-row>
                <v-col md="12">
                  <v-row align="center" fill-height>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="
                          stats.totalWithdrawedPoints +
                          stats.totalWrittenOffPoints
                        "
                        title="已清算總點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col
                      v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      cols="1"
                      align="center"
                      style="font-size: 2.4rem"
                    >
                      ＝
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalWithdrawedPoints"
                        title="已請款總點數"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="2" cols="12">
                      <CardStatNumber
                        :number="stats.totalWrittenOffPoints"
                        title="已註銷總點數"
                      ></CardStatNumber>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="stretch">
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="
              stats.totalUnusedPoints +
              stats.totalUserPoints +
              stats.totalStorePoints +
              stats.totalUsedExpiredPoints +
              stats.totalWithdrawingPoints
            "
            title="總點數"
          ></CardStatNumber>
        </v-col>
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="stats.memberCount"
            title="目前會員數"
          ></CardStatNumber>
        </v-col>
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="stats.storeCount"
            title="目前特店數"
          ></CardStatNumber>
        </v-col>
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="stats.transactionPointsToday"
            title="本日交易點數"
          ></CardStatNumber>
        </v-col>
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="stats.rewardPointsToday"
            title="本日活動贈點"
          ></CardStatNumber>
        </v-col>
        <v-col md="2" cols="12">
          <CardStatNumber
            :number="stats.allocatedPointsToday"
            title="本日新增預算點數"
          ></CardStatNumber>
        </v-col>
      </v-row>
      <v-row align="stretch">
        <v-col md="4" cols="12" v-if="isTopNLoaded">
          <pointTopN
            :table-data="topN.topUsers"
            type="member"
            title="Top10 會員點數"
          >
          </pointTopN>
        </v-col>
        <v-col md="4" cols="12" v-if="isTopNLoaded">
          <pointTopN
            :table-data="topN.topStores"
            type="store"
            title="Top10 特店未請款點數"
          >
          </pointTopN>
        </v-col>
        <v-col md="4" cols="12" v-if="isTopNLoaded">
          <pointTopN
            :table-data="topN.topTransactions"
            type="store"
            title="Top10 本日點數交易記錄"
          >
          </pointTopN>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid class="px-6 py-6">
      您尚未被授權檢視此頁面
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchPointStats, fetchPointTopN } from "src/apis/fetchData";
import { formatNumber, checkPermission } from "src/util/utils";
import CardStatNumber from "./Widgets/CardStatNumber.vue";
import PointTopN from "./Widgets/PointTopN.vue";

export default {
  name: "Dashboard",
  components: {
    CardStatNumber,
    PointTopN,
  },
  data() {
    return {
      moment: moment,
      isStatsLoaded: true,
      stats: {},
      formatNumber: formatNumber,
      isTopNLoaded: true,
      topN: {},
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    isActionDisabled() {
      return !this.isStatsLoaded;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    canView() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "ViewPointDashboard",
          true
        );
      }
      return false;
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
  },
  methods: {
    init() {
      this.getPointStats();
      this.getPointTopN();
    },
    getPointStats() {
      this.isStatsLoaded = false;
      let today = moment().format("YYYY-MM-DD").toString();
      let query = {
        startTs: moment(today).unix(),
        endTs: moment(today).unix() + 24 * 60 * 60,
      };
      fetchPointStats(this.merchantId, query)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          if (res && res.data) {
            this.stats = res.data;
          } else {
            this.stats = {};
          }
          console.log("this.stats:" + JSON.stringify(this.stats));
        })
        .catch((e) => {
          console.log("fetchPointStats failed", e);
          let errmsg = "無法取得點數總覽資料";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(null, errmsg);
        })
        .finally(() => {
          this.isStatsLoaded = true;
        });
    },
    getPointTopN() {
      this.isTopNLoaded = false;
      let today = moment().format("YYYY-MM-DD").toString();
      let query = {
        startTs: moment(today).unix(),
        endTs: moment(today).unix() + 24 * 60 * 60,
      };
      fetchPointTopN(this.merchantId, query)
        .then((res) => {
          console.log("fetchPointTopN done", res);
          if (res && res.data) {
            this.topN = res.data;
          } else {
            this.topN = {
              topUsers: [],
              topStores: [],
              topTransactions: [],
            };
          }
          console.log("this.topN:" + JSON.stringify(this.topN));
        })
        .catch((e) => {
          console.log("fetchPointTopN failed", e);
          let errmsg = "無法取得點數排行榜資料";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(null, errmsg);
        })
        .finally(() => {
          this.isTopNLoaded = true;
        });
    },
    showErrorAlert(title, message) {
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
